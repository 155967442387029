import type { LocaleCode } from '#types/locale'

/**
 * Returns a number formatted according to the specified locale settings, with locale-specific
 * thousands separators.
 *
 * @param value - The number to be formatted.
 * @param locale - The locale to use for formatting (default is 'en-us').
 * @example
 * getLocalizedNumber(1000, 'en-us')
 * returns '1,000'
 */
export const getLocalizedNumber = (value: number, locale?: LocaleCode) => {
  return new Intl.NumberFormat(locale || useLocale()).format(value)
}
